import './App.css';
import { useEffect, useState } from 'react';
import { getTranslations } from './locales';

// Bayrak görsellerini import edin
import enFlag from './assets/flags/en.png';
import trFlag from './assets/flags/tr.png';

function App() {
    const [language, setLanguage] = useState("en");
    const [translations, setTranslations] = useState(getTranslations("en"));
    const [dropdownActive, setDropdownActive] = useState(false);

    useEffect(() => {
        const lang = localStorage.getItem("language");
        if (lang) {
            setLanguage(lang);
            setTranslations(getTranslations(lang));
        }
    }, []);

    const handleLanguageChange = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        setTranslations(getTranslations(selectedLanguage));
        localStorage.setItem("language", selectedLanguage);
        setDropdownActive(false); // Seçim yapıldıktan sonra dropdown'u kapat
    };

    const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
    };

    return (
        <div className="App">
            <div className="header">
                <div className="headerLeftLabel">
                    <b>{translations.title}</b>
                </div>
                <div className="language">
                    <div className="languageSelector" onClick={toggleDropdown}>
                        <img src={language === 'en' ? enFlag : trFlag} alt="Current Language" />
                    </div>
                    <div className={`languageOptions ${dropdownActive ? 'active' : ''}`}>
                        <div className="languageOption" onClick={() => handleLanguageChange('en')}>
                            <img src={enFlag} alt="English" />
                            <span>English</span>
                        </div>
                        <div className="languageOption" onClick={() => handleLanguageChange('tr')}>
                            <img src={trFlag} alt="Türkçe" />
                            <span>Türkçe</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="background">
                <div className="label">
                    {translations.header}
                </div>
                <div className="labelText">
                    {translations.subheader}
                </div>

                <div className="footer">
                    <b className="footerLabel">{translations.footer}</b>
                </div>
            </div>
        </div>
    );
}

export default App;
