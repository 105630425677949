import en from './en.json';
import tr from './tr.json';

const locales = {
    en,
    tr,
};

export const getTranslations = (language) => {
    return locales[language] || locales.en;
};
